'use client'
import { useSearchType } from '@/api/hooks'
import { Banner as BannerType } from '@/app/util/banner'
import Banner from '@/components/banner'
import { SectionHeader } from '@/components/sections'
import Toggle from '@/components/toggle'
import { SearchTypeContext } from '@/context/search-type'
import { setGADataLayer } from 'analytics'
import { isEmpty } from 'lodash'
import { useContext } from 'react'
import styled, { css } from 'styled-components'
import { desktop } from 'ui/src/css'
import TrendingIcon from '../icons/trending'
import { searchTypes } from './search/types'

const GridHeader = styled.div`
    ${desktop(css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    `)}
`

type Props = {
    banner?: BannerType
    children?: React.ReactNode
}

const HomeHeader = ({ banner, children }: Props) => {
    const { setTransitioning } = useContext(SearchTypeContext)
    const searchType = useSearchType()

    return (
        <div className="mb-2.5 mt-1 flex flex-col gap-1.5 md:my-2.5 md:flex-col-reverse md:gap-2.5">
            <GridHeader>
                <SectionHeader
                    Icon={<TrendingIcon className="size-5" />}
                    label="Trending Now"
                    tag="h1"
                    className="hidden md:inline-block"
                />
                <Toggle
                    className="bg-gradient-grey md:w-[400px]"
                    index={Math.max(searchTypes.indexOf(searchType || 'gifs'), 0)}
                    onClick={(index) => {
                        setTransitioning(true)
                        const nextType = searchTypes[index]
                        setGADataLayer({
                            event: 'page_view',
                            options: {
                                pageType: 'home',
                                pageCategory: `homepage_${nextType}`,
                            },
                        })
                    }}
                    options={[
                        { label: 'GIFs', href: '/' },
                        { label: 'Stickers', href: '/?type=stickers' },
                        { label: 'Clips', href: '/?type=clips' },
                    ]}
                />
            </GridHeader>
            {!isEmpty(banner) && <Banner banner={banner} />}
            {children}
        </div>
    )
}

export default HomeHeader
