'use client'
import { useSearchType } from '@/api/hooks'
import { publicRuntimeConfig } from '@/app/util/env'
import Grid from '@/components/grid/grid'
import VideoGrid from '@/components/grid/video-grid'
import { SearchTypeContext } from '@/context/search-type'
import UAParserContext from '@/context/ua-parser'
import { GiphyFetch, MediaType } from '@giphy/js-fetch-api'
import { IGif } from '@giphy/js-types'
import { useContext } from 'react'
import styled, { css } from 'styled-components'
import { desktop } from 'ui/src/css'
import Loader from '../components/loader'

const PageContent = styled.div`
    margin-top: 6px;
    position: relative;
    ${desktop(css`
        margin-top: 0;
    `)}
`

type Props = {
    trendingGifs?: IGif[]
    children?: React.ReactNode
}

const fetchConfig = {
    clientMobile: 12,
    clientDesktop: 12,
} as const

const clientGiphyFetch = new GiphyFetch(publicRuntimeConfig.mobileApiKey)

const getTrackingUrl = (page: number) => `/homepage/page/${page}`

const HomeGrid = ({ trendingGifs = [], children }: Props) => {
    const { deviceType } = useContext(UAParserContext)
    const { isTransitioning } = useContext(SearchTypeContext)
    const type = useSearchType()
    const trending = (type?: MediaType) => async (offset: number) =>
        clientGiphyFetch.trending({
            offset,
            type,
            limit: deviceType === 'desktop' ? fetchConfig.clientDesktop : fetchConfig.clientMobile,
        })

    const gridFetch = trending(type == 'clips' ? 'videos' : type)
    return (
        <PageContent>
            {isTransitioning ? (
                <Loader />
            ) : type === 'clips' ? (
                <VideoGrid fetchGifs={gridFetch} gaTrackingUrl={getTrackingUrl} />
            ) : (
                <Grid
                    key={type}
                    initialGifs={type === 'gifs' ? trendingGifs : undefined}
                    fetchGifs={gridFetch}
                    columnOffsets={/*type === 'gifs' ? [0, 0, 350, 350] : */ undefined}
                    gaTrackingUrl={getTrackingUrl}
                />
            )}
            {type === 'gifs' && children}
        </PageContent>
    )
}

export default HomeGrid
