import { cn } from '@/util/cn'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'

type Option = {
    label: string
    /**
     * Include hrefs in the toggle
     * This is useful for SEO
     * Currently this does not render correctly for users, but it works for bots
     */
    href?: string
}

type ToggleProps = {
    className?: string
    options: Option[]
    index: number
    onClick?: (index: number) => void
    // use this if you need to navigate during the transition
    // if you try to update the url during an animation, it will cancel the animation
    onTransitionEnd?: (index: number) => void
}
const Toggle = ({ className, options, onClick, index, onTransitionEnd }: ToggleProps) => {
    const [_index, setIndex] = useState(index)
    const isTransitioning = useRef(false)
    useEffect(() => {
        if (!isTransitioning.current && index !== _index) {
            setIndex(index)
        }
    }, [_index, index, setIndex])
    const buttonWidth = `${100 * (1 / options.length)}%`
    const left = `${100 * (_index / options.length)}%`
    const gradient = ['bg-gradient-primary', 'bg-gradient-secondary', 'bg-gradient-tertiary']
    return (
        <div
            className={cn(
                'bg-gradient-grey text-giphyWhite relative flex justify-between rounded-3xl font-bold md:w-[400px]',
                className
            )}
        >
            <>
                <div
                    className={cn(
                        'duration-250 absolute left-0 h-full rounded-[inherit] transition-all',
                        gradient[_index % options.length]
                    )}
                    onTransitionEnd={() => {
                        onTransitionEnd?.(_index)
                        isTransitioning.current = false
                    }}
                    style={{
                        width: buttonWidth,
                        left,
                    }}
                />
                {options.map((option, index) => {
                    return option.href ? (
                        <Link
                            replace
                            onClick={() => {
                                isTransitioning.current = true
                                setIndex(index)
                                onClick?.(index)
                                return
                            }}
                            className="relative cursor-pointer rounded-3xl p-2 text-center text-[inherit]"
                            key={option.label}
                            style={{ width: buttonWidth }}
                            href={option.href}
                        >
                            {option.label}
                        </Link>
                    ) : (
                        <button
                            onClick={() => {
                                isTransitioning.current = true
                                setIndex(index)
                                onClick?.(index)
                                return
                            }}
                            className="relative cursor-pointer rounded-3xl p-2 text-[inherit]"
                            key={option.label}
                            style={{ width: buttonWidth }}
                        >
                            {option.label}
                        </button>
                    )
                })}
            </>
        </div>
    )
}
export default Toggle
